<template>
  <div>
    <h1>Página não encontrada</h1>
    <img src="/img/404.svg" width="500px" alt="Pagina não encontrada" />
    <router-link class="retornar" to="/login">Retornar</router-link>
  </div>
</template>

<script>
export default {
  name: "404",
};
</script>

<style scoped>
div {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Nunito", sans-serif;
}
h1 {
  margin-bottom: 20px;
  color: #2f2e41;
}
.retornar {
  font-size: 22px;
  margin-top: 20px;
}

@media (max-width: 600px) {
  img {
    width: 300px;
  }
}
</style>

